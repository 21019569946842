@font-face {
  font-family: 'Noto Sans KR';
  font-style: thin;
  font-weight: 100;
  src: local('NotoSansKR'),
    url(./../assets/fonts/NotoSans-Thin.woff2) format('woff2');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: light;
  font-weight: 300;
  src: local('NotoSansKR'),
    url(./../assets/fonts/NotoSans-Light.woff2) format('woff2');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: regular;
  font-weight: 400;
  src: local('NotoSansKR'),
    url(./../assets/fonts/NotoSans-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: medium;
  font-weight: 500;
  src: local('NotoSansKR'),
    url(./../assets/fonts/NotoSans-Medium.woff2) format('woff2');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: bold;
  font-weight: 700;
  src: local('NotoSansKR'),
    url(./../assets/fonts/NotoSans-Bold.woff2) format('woff2');
}
